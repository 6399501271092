/*----------CUSTOM SCROLLBAR----------*/
.theme_scroll::-webkit-scrollbar {
    width: 6px !important;
    height: 6px !important;
    display:block !important;
}

.theme_scroll::-webkit-scrollbar-track {
    background-color: #edf0f1;
    border-radius: 5px;
}

.theme_scroll::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: $standard-blue-secondary;
}
.theme_scroll{
  -ms-overflow-style: auto;
   scrollbar-width: auto;
   overflow: auto;
}


/*----------CUSTOM SCROLLBAR----------*/
.theme_scroll_secondary::-webkit-scrollbar {
    width: 3px !important;
    height: 3px !important;
    display: block !important;
}

.theme_scroll_secondary::-webkit-scrollbar-track {
    background-color: #edf0f1;
    border-radius: 5px;
}

.theme_scroll_secondary::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #cccccc;
}
.theme_scroll_secondary {
  -ms-overflow-style: auto;
   scrollbar-width: auto;
   overflow: auto;
}
