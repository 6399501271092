@import "~ng-pick-datetime/assets/style/picker.min.css";
@import "app/styles/generic-color-codes.styles.scss";
@import "app/styles/global-buttons.styles.scss";
@import "app/styles/custom-scrollbar.styles.scss";
@import "@angular/material/prebuilt-themes/indigo-pink.css";

$generic-padding-for-page: 20px 40px;
$generic-box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);

html {
    font-size: 16px;
    height: 100vh;
    overflow: hidden;
    font-family: 'Roboto', sans-serif;
    padding: 0;
    margin: 0;
}

body {
    height: 100vh;
    font-family: 'Roboto', sans-serif;
    padding: 0;
    margin: 0;
}

* {
    box-sizing: border-box;
}

.padding-top-bottom-20 {
  padding: 20px 0;
}

.disabled-custom-checkbox {
  .checkmark {
      cursor: not-allowed !important;
  }
}

.header-back-button {
  display: inline-block;
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
}

.generic-page-container { //NEW PAGE CONTAINER
  @extend .theme_scroll;
  height: 100% !important;
  
  padding: 15px;
  // padding-left: 40px;
  h5 {
      margin: 0;
      font-size: 18px;
      color: #333333;
      margin-bottom: 15px;
      font-weight: 600;
  }
}

.generic-white-container { //NEW WHITE CONTAINER
  background: #FFFFFF;
  border: 1px solid #E4E4E4;
  border-radius: 15px;
  opacity: 1;
  padding: 20px;
}



/*---------CUSTOM LINKS---------*/
.custom-link {
  color: $standard-blue-new !important;
  font-size: 12px;
  text-decoration: underline;
  font-weight: 600;
  cursor: pointer;
}

/*-----------CURSOR-----------*/
.hover-pointer {
  cursor: pointer;
}

.not-allowed {
  cursor: not-allowed;
}

/*-----------CONTAINER(s)-----------*/
.white-container, .generic-card {
  background-color: white;
  border-radius: 6px;
  box-shadow: $generic-box-shadow;
}

.general-container {
  width: 100%;
  height: 100%;
  display: flex;    
  padding: 20px 40px;
  overflow: auto;
}

/*----------VALIDATION ERROR----------*/
.validation-error {
  font-size: 12px;
  color: $validation-error-color !important;
}

.required-icon {
  font-size: 12px;
  color: $validation-error-color;
}

.input-validation-error {
  border: 1px solid $validation-error-color !important;
}

.error-input {
  border: 1px solid $validation-error-color !important;
  label {
    color: $validation-error-color !important;
  }
}

/*----------NGX PAGINATION----------*/
.ngx-pagination {
  padding-left: 0px !important;
  font-size: 14px !important;
}

.ngx-pagination .current, .btn-info {
  background: $generic-blue !important;
  border: transparent !important;
  border-radius: 20px !important;
}

.ngx-pagination .current:hover {
  background: $generic-blue !important;
  border-radius: 20px !important; 
  border: transparent !important;
}

.logout-icon {
  padding-left: 10px;
}

/*----------OTHER----------*/
.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.half-width {
  width: 50%;
}

.flex-row {
  display: flex;
}

.flex-column {
  display: flex;
  flex-flow: column;
}

.align-center {
  align-items: center;
}

.center-align-text {
  text-align: center;
}

.idc-logo img {
  height: 30px;
  width: auto;
}

.add-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.filter-labels {
  font-size: 14px;
  font-weight: 500;
}

.remove-bullets {
  list-style-type:none;
}

.download-icon {
  height: 20px;
  cursor: pointer;
}

.generic-data-container {
  height: 100%;
  width: 100%;
  padding: 15px 18px 15px 18px;

  h4 {
      margin-bottom: 20px;
      font-size: 22px;
      // font-weight: 600;
      color: #2f3744;
  }
}

.divider-30 {
  height: 30px;
}

.word-break-idc {
  word-break: break-word;
}

#billing-bucket-chart .bar {
  fill: #5f89ad;
}

.axis {
  font-size: 13px;
}

.axis path,
.axis line {
  fill: none;
  display: none;
}

.label {
  font-size: 13px;
}

.domain {
  stroke: grey;
}


.chart-no-data {
  color: grey;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chart-loading {
  @extend .chart-no-data;
  font-style: italic;
}

/* Add application styles & imports to this file! */
.bullet { font: 10px sans-serif; }
.bullet .marker { stroke: #000; stroke-width: 2px; }
.bullet .tick line { stroke: #666; stroke-width: .5px; }
.bullet .range.s0 { fill: #eee; }
.bullet .range.s1 { fill: #ddd; }
.bullet .range.s2 { fill: #ccc; }
.bullet .measure.s0 { fill: lightsteelblue; }
.bullet .measure.s1 { fill: steelblue; }
.bullet .title { font-size: 14px; font-weight: bold; }
.bullet .subtitle { fill: #999; }

.chart-tooltip{
  position: absolute;
  color: black; 
  text-align: center;
  width: auto;
  border-radius: 4px;
  padding: 8px;
  margin-top: -40px;
  font: 10px sans-serif;
  background: #000000;
  color: #ffffff;
  pointer-events: none;
}

/*---------NGX-PAGINATION CUSTOM CSS----------*/
.ngx-pagination {
  text-align: center;
}

.ngx-pagination .current {
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  padding: 16px !important;
  color: #ffffff;
  background-color: $standard-blue-new !important;
  border-radius: 50% !important;
  height: 16px !important;
  width: 16px !important;
}

.ngx-pagination .current:hover {
  background: $standard-blue-new !important;
}

.ngx-pagination a:hover {
  border: none;
  outline: none;
  text-decoration: none;
}

/*----------NGX RANGE SLIDER CUSTOMIZATION----------*/
.ngx-slider .ngx-slider-tick-value {
  color: #BFBFBF;
  font-size: 12px;
}

.ngx-slider .ngx-slider-pointer {
  height: 18px !important;
  width: 30px !important;
  box-shadow: rgba(51, 51, 51, 0.16) 0px 0px 5px !important;
  background-color: #ffffff !important;
  border-radius: 3px !important;
  top: -4px !important;
}

.ngx-slider .ngx-slider-bar-wrapper {
  visibility: visible !important;
  background-color: #1EBAED !important;
  border-radius: 5px !important;
  height: 10px !important;
  padding: 0 !important;
  margin: 0 !important;
}

.ngx-slider .ngx-slider-selection, .ngx-slider .ngx-slider-full-bar {
  border-radius: 5px !important;
}

.ngx-slider .ngx-slider-full-bar {
  background-color: transparent !important;
}

.ngx-slider .ngx-slider-pointer:after {
  display: none;
}

.ngx-slider .ngx-slider-tick-value {
  top: 21px !important;
}

.ngx-slider .ngx-slider-bar {
  height: 10px !important;
  border-radius: 5px !important;
  border: 1px solid #E6E6E6 !important;
}

.ngx-slider .ngx-slider-tick { 
  width: 0 !important;
  height: 0 !important;
}

/*----------MOBILE MEDIA QUERIES-----------*/
@media (max-width: 767px) {
  .generic-page-container, .generic-data-container {
    padding: 15px !important;
    margin: 0;
  }
}

.owl-dt-calendar-table .owl-dt-calendar-cell-today:not(.owl-dt-calendar-cell-selected) {
  background-color: transparent !important;
  border-radius: 6px;
  font-weight: bold;
  // color: #ffffff !important;
  border: none !important;
}

.owl-dt-calendar-table .owl-dt-calendar-cell-content:hover {
  border-radius: 6px !important;
}

.owl-dt-calendar-control {
  font-size: 14px;
  color: rgba(0,0,0,.4) !important;
}

.owl-dt-calendar-table .owl-dt-calendar-cell-selected {
  background-color: #0dabdd !important;
  border-radius: 6px;
  color: #ffffff !important;
  border: none !important;
}

.owl-dt-timer-divider {
  display: none !important;
}

.owl-dt-control-button-content {
  display: none;
}

// .owl-dt-container-buttons {
//   display: none;
// }

// .owl-dt-control-arrow-button svg {
//   display: none !important;
// }

// .owl-dt-timer-content:first-child::before {
//   content: "<span>Start Time</span>"
// }

// .owl-dt-timer-box {
//   margin: 0;
//   width: auto !important;
// }

.owl-dt-timer-input {
  font-size: 14px;
}

.dateTimeChange {
  height: auto;
}

.countryxx .landxx, .countryxx.landxx {
  stroke: #d4d4d4 !important;
}


// .ng2-pdf-viewer-container {
//   overflow: hidden;
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
// }

.red-snackbar{
  background-color: rgb(153, 50, 50) !important;
  color:lightgoldenrodyellow;
}

.form-control.is-invalid,
.was-validated .form-control:invalid,
.is-invalid {
    background-image: none !important;
    border-color: #FA5C66 !important;
}