$nav-panel-color: #00188f;
$panel-dark-grey: rgb(68,68,68);
$panel-medium-grey: rgb(151, 151, 151);
$panel-light-grey: rgb(249,249,249);
$side-bar-text-color: rgb(200,200,200);
$generic-blue: #00BCF2;
$generic-blue_dark: rgb(2, 172, 219);

/*----------UI REFACTOR---------*/
$standard-blue-dark: rgb(31, 16, 101);
$standard-blue: #17149E;
$side-nav-color: #444445;
$standard-grey-bg: rgb(249, 249, 249);
$validation-error-color: #e53e51;

/*----------NEW DESIGN----------*/
$generic-green: rgb(31, 185, 0);
$generic-red: rgb(210,0,25);
$standard-blue-new: #0C4DB2;
$standard-grey-new: #8E8E8E;
$standard-grey-dark-new: #212529;
$standard-border-new:#ced4da;
$standard-blue-secondary: #00BCF2;
