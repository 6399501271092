/*----------BUTTONS----------*/
.generic-button-blue {
    background-color: $standard-blue-new !important;  
    border: 1px solid $standard-blue-new;
    opacity: 0.9;  
    font-size: 14px;
}

.generic-button-blue:hover {
    border: 1px solid$standard-blue-new;
    opacity: 1;    
}

.generic-button-blue:disabled {
    border: 1px solid$standard-blue-new;
    opacity: 0.65;
}

.generic-button-blue-round {
    @extend .generic-button-blue;    
    padding: 5px 30px;
    border-radius: 30px;
}

.generic-button-white {
    @extend .generic-button-blue;
    background-color: #ffffff !important;  
    color:$standard-blue-new;
}

.generic-button-white:hover {
    color:$standard-blue-new;
}

.full-width-blue-button {
    background-color:$standard-blue-new !important;
    width: 100%;
    font-size: 14px;
}

.clear-grey-button {
    background-color: transparent !important;
    border: none !important;
    outline: none !important;
    color: #707070 !important;
}

.clear-grey-button:focus, .clear-grey-button:hover {
    background-color: transparent !important;
    border: none !important;
    outline: none !important;
    color: #707070 !important;
}

.pane-view-blue-button {
    padding: 6px 44px !important;
    border-radius: 8px !important;
    background-color: $standard-blue-new !important;
    color: #ffffff !important;
    font-size: 14px !important;
}

.sec-pane-view-blue-button {
    @extend .pane-view-blue-button;
    padding: 8px 10px !important;
    background-color: #1EBAED !important;
    border-color: #1EBAED !important;
}

.sec-blue-border-btn {
    @extend .generic-button-blue;
    border: 1px solid #1EBAED !important;
    background-color: #ffffff !important;
    color:  #1EBAED !important;
    font-size: 14px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 11px 12px;
    min-width: 80px;
}

.sec-blue-border-btn:disabled {
    cursor: not-allowed;
    border: 1px solid #1EBAED !important;
    opacity: 0.4;
}

.sec-blue-border-btn:hover {
    border: 1px solid #1EBAED !important;
}